import logoImage from './assets/img/logo.png';
// import logoImage from './assets/img/logo-kari.png';
import headerImage from './assets/img/header.jpg';
// import headerImage from './assets/img/header-kari.jpg';
import lastMinuteImage from './assets/img/last-minute.png';
import fontFamily from './assets/fonts/Roboto-Regular.ttf';

(window as any).config = {
    uid: 'foodplusz',
    logoImage: logoImage,
    headerImage: headerImage,
    cartImage: null,
    lastMinute: {
        text: 'A következő választékunk rendelhető a készlet erejéig (Fontos, hogy a Last minute során megrendelt ételek lemondására nincs lehetőség. Kérjük, fokozott figyelemmel adjanak le rendelést és válasszanak fizetési módot!): A betűjel végén szereplő „Z” kis adagot jelöl. Szombati ételeink pénteken kerülnek kiszállításra.',
        image: lastMinuteImage
    },
    projectName: 'FoodPlusz',
    projectSlogan: 'Frissen főzve, frissen hűtve',
    loginWelcome: 'Üdv újra a FoodPlusz weblapján, kérem jelentkezzen be!',
    pdf: {
        fontFamily: fontFamily
    },
    constans: {
        CONST_URL_FOOLDAL: 'https://foodplusz.hu/',
        CONST_URL_GYIK: 'https://foodplusz.hu/gyakori-kerdesek/',
        CONST_URL_KAPCSOLAT: 'https://foodplusz.hu/kapcsolat/',
        CONST_URL_TUDNIVALOK: 'https://foodplusz.hu/tudnivalok/',
        CONST_URL_SZALLITASI_TERULETEK: 'https://foodplusz.hu/szallitasi-teruletek/',
        CONST_URL_ADATVEDELMI: 'https://foodplusz.hu/adatkezelesi-tajekoztato/',
        CONST_URL_ASZF: 'https://foodplusz.hu/altalanos-szerzodesi-feltetelek/'
    },
    urls: {
        prod: 'https://fa.foodplusz.hu',
        dev: 'https://foodplusz.dev.diff.hu',
        local: 'https://192.168.215.147:10001'
    },
    bugsnag: {
        api: 'b62e28fa441d74a5466cae0cc5644f67'
    },
    sentry: {
        dns: 'https://eb75a80acdacbe2a511fd063db8a139c@o1038507.ingest.us.sentry.io/4508284213854208'
    },
    payment_methods: ['cash', 'barion', 'mkb_szep_card', 'otp_szep_card', 'kh_szep_card'],
    hasMultilingualMenu: false
};

import './variables.scss';
import('../../src/main')
    .then(module => {
        // The module is now loaded and executed
        console.log(`[${window.config?.uid ?? ''}] Main module loaded!`);
    })
    .catch(error => {
        console.error('❌ Error loading main module:', error);
    });
